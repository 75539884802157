<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="departmentData === undefined"
    >
      <h4 class="alert-heading">
        Errore dati Reparto
      </h4>
      <div class="alert-body">
        Nessun Reparto trovato con questo ID. Controlla
        <b-link
          class="alert-link"
          :to="{ name: 'apps-departments-list' }"
        >
          Lista Reparti
        </b-link>
        per altri Reparti.
      </div>
    </b-alert>

    <template v-if="departmentData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <departments-view-department-info-card :department-data="departmentData" />
        </b-col>
        <b-col cols="12">
          <departmentusers-list :can-manage="false" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import DepartmentusersList from '@/views/apps/departmentusers/departmentusers-list/DepartmentusersList.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import DepartmentsViewDepartmentInfoCard from './DepartmentsViewDepartmentInfoCard.vue'
import departmentsStoreModule from '../departmentsStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    DepartmentusersList,
    DepartmentsViewDepartmentInfoCard,
  },
  setup() {
    const toast = useToast()
    const departmentData = ref(null)

    const DEPARTMENTS_APP_STORE_MODULE_NAME = 'app-departments'

    // Register module
    if (!store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(DEPARTMENTS_APP_STORE_MODULE_NAME, departmentsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENTS_APP_STORE_MODULE_NAME)
    })

    store
      .dispatch('app-departments/fetchDepartment', { id: router.currentRoute.params.id })
      .then(response => {
        departmentData.value = response.data
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })

    return {
      departmentData,
    }
  },
}
</script>

<style></style>
